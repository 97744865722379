/*
 * 업무구분: 영업
 * 화 면 명: MSPFS302M
 * 화면설명: 대리인4100
 * 작 성 일: 2023.05.11
 * 작 성 자: 송진의
 */
<template>
  <ur-page-container
    class="fts"
    :show-title="false"
    :title="pHeaderObj.title"
    :topButton="true"
  >
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top gap40">
          <div class="left column mr0">
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label> 고객번호 </label>
                <mo-text-field  	                
                  ref="ed_cust_no"
                  type="number"
                  class="w150 flex-unset"
                  :class="lv_ed_cust_no.error ? 'error' : ''"
                  v-model="lv_ed_cust_no.value"                  
                  maxlength="7"
                  @keyup="fn_SearchValid($event)"
                />
                <mo-button @click="fn_OpenPopup(0)"> 고객정보 </mo-button>
                <mo-text-field
                  class="w130 flex-unset"
                  v-model="lv_ed_cust_nm.value"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="right">
            <div class="row w100p">
              <div class="wrap-button row">
                <mo-button class="clear" @click="fn_Init()"> 초기화 </mo-button>
                <mo-button
                  primary
                  class="inquiry"
                  @click="fn_OnSearchList()"
                >
                  조회
                </mo-button>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <!-- <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info> -->
      <!-- fs-cust-info end -->

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">기간별내역</h2>
          <span class="txt-length"
            >※ 지정일, 만기일 등 선택 시 대리인 정보가 상세 조회됩니다.</span
          >
        </div>
        <div class="wrap-table h-scroll-200">
          <table class="table row-type">
            <thead>
              <tr>
                <th>지정일</th>
                <th>만기일</th>
                <th>등록일</th>
                <th>일련번호</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, i) in ds_list"
                :key="i"
                @click="fn_OnSelect(item)"
              >
                <!-- [수정] 선택 시 checked class추가 :class="{'checked': selectList == (i+1)}"-->
                <td>{{ $bizUtil.dateDashFormat(item.asgn_ymd) }}</td>
                <td>{{ $bizUtil.dateDashFormat(item.exp_ymd) }}</td>
                <td>{{ $bizUtil.dateDashFormat(item.reg_ymd) }}</td>
                <td>{{ item.agnt_sno }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="wrap-button result mb0">
          <mo-button :disabled="lv_btn_add_agnt" @click="fn_OnAddAgnt()">
            기간추가
          </mo-button>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">대리인</h2>
          <span class="txt-length"
            >※ 수정, 삭제는 당일 입력건에 한해 가능합니다.</span
          >
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 성명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      ref="ed_agnt_nm"                      
                      :class="lv_ed_agnt_nm.error ? 'error' : ''"
                      v-model="lv_ed_agnt_nm.value"
                      :disabled="lv_ed_agnt_nm.disabled"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 국적 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      ref="cob_naty_cntry_cd"                      
                      :class="lv_cob_naty_cntry_cd.error ? 'error' : ''"
                      :items="lv_commCode.code981"
                      v-model="lv_cob_naty_cntry_cd.value"
                      :disabled='lv_cob_naty_cntry_cd.disabled'
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 실명번호 종류 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_cob_agnt_rname_gb.error ? 'error' : ''"
                      :items="lv_commCode.code211"
                      v-model="lv_cob_agnt_rname_gb.value"
                      :disabled="lv_cob_agnt_rname_gb.disabled"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 실명번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="ed_agnt_rname_no1"
                      type="number"
                      :class="lv_ed_agnt_rname_no.error1 ? 'error' : ''"
                      v-model="lv_ed_agnt_rname_no.value1"
                      clearable 
                      placeholder="앞 6자리"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                      maxlength="6"
                      @input="fn_NumKeyUp"
                    />
                    <m-trans-key-input
                      v-if="lv_isMobile && isMtrans"
                      ref="ed_agnt_rname_no2"
                      v-model.trim="lv_ed_agnt_rname_no.value2"
                      type="numberMax7"
                      dialog="Y"
                      start="1" 
                      end="-1"                      
                      @masked-txt="fn_SetMaskedTxt"
                      :isClear="lv_isClear"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                    />
                    <mo-text-field
                      v-else
                      ref="ed_agnt_rname_no2"
                      type="password"
                      class="input-long"
                      :class="lv_ed_agnt_rname_no.error2 ? 'error' : ''"
                      v-model="lv_ed_agnt_rname_no.value2"
                      clearable 
                      placeholder="뒤 7자리"
                      :disabled="lv_ed_agnt_rname_no.disabled"
                      maxlength="7"
                    />
                    <!-- [수정] 실명번호 두칸 나누기-->
                    <!-- <mo-text-field v-model="" placeholder="앞 6자리" class="input-long" maxlength="6" />
                    <mo-text-field type="password" v-model="" clearable placeholder="뒤 7자리" class="input-long" maxlength="7" /> -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 영문명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref= 'ed_agnt_enm'
                      class="input-long"
                      :class="lv_ed_agnt_enm.error ? 'error' : ''"
                      v-model="lv_ed_agnt_enm.value"
                      :disabled="lv_ed_agnt_enm.disabled"
                    />
                  </div>
                </td>
                <th>
                  <span> 여권번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref='ed_agnt_psprt_no'
                      class="input-long"
                      :class="lv_ed_agnt_psprt_no.error ? 'error' : ''"
                      v-model="lv_ed_agnt_psprt_no.value"
                      :disabled="lv_ed_agnt_psprt_no.disabled"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 생년월일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker
                      class="input-long"
                      :class="lv_cal_birth_dt.error ? 'error' : ''"
                      v-model="lv_cal_birth_dt.value"
                      :bottom="false"
                      :disabled="lv_cal_birth_dt.disabled"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 성별 </span>
                </th>
                <td>
                  <mo-radio-wrapper
                    :items="gender"
                    v-model="lv_rdo_agnt_sex.value"
                    :disabled="lv_rdo_agnt_sex.disabled"
                  />
                </td>
              </tr>
              <tr>
                <th rowspan="5">
                  <span class="emphasis"> 주소 </span>
                </th>
                <td>
                  <!-- 주소유형 -->
                  <div class="wrap-input row">
                    <mo-dropdown
                      ref="cob_main_dm"                      
                      :class="lv_cob_main_dm.error ? 'error' : ''"
                      :items="lv_commCode.code215"
                      v-model="lv_cob_main_dm.value"
                      :disabled="lv_cob_main_dm.disabled"
                      :bottom="false"
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">                    
                    <mo-text-field
                      type="number"
                      class="w80"
                      maxlength = "3"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value1"
                      :disabled="lv_home_no.disabled"
                    />
                    <mo-text-field
                      type="number"
                      class="w60"
                      maxlength = "4"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value2"
                      :disabled="lv_home_no.disabled"
                    />
                    <mo-text-field
                      type="number"
                      class="w60"
                      maxlength = "4"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value3"
                      :disabled="lv_home_no.disabled"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소 우편번호 -->
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      :class="lv_home_addr.error ? 'error' : ''"
                      v-model="lv_home_addr.zipNum"                      
                      disabled
                    />
                    <mo-button
                      class="btn-zip"
                      :disabled="lv_home_addr.disabled"
                      @click="fn_OpenPopup(1, 'HOME')"
                    >
                      우편번호
                    </mo-button>
                  </div>
                </td>
                <th class="bl-ddd">
                  <span> 휴대폰 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      class="w80"
                      :class="lv_phone_no.error ? 'error' : ''"
                      :items="lv_commCode.code370"
                      v-model="lv_phone_no.value1"                      
                      :disabled="lv_phone_no.disabled"
                    />
                    <mo-text-field
                      type="number"
                      class="w60"
                      maxlength = "4"
                      :class="lv_phone_no.error ? 'error' : ''"
                      v-model="lv_phone_no.value2"
                      :disabled="lv_phone_no.disabled"
                    />
                    <mo-text-field
                      type="number"
                      class="w60"
                      maxlength = "4"
                      :class="lv_phone_no.error ? 'error' : ''"
                      v-model="lv_phone_no.value3"
                      :disabled="lv_phone_no.disabled"
                    />
                    <mo-button
                      @click="fn_DeletePhoneNo"
                      :disabled="lv_phone_no.delete"                      
                    >
                      삭제
                    </mo-button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소1 / 2 -->
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      :class="lv_home_addr.error ? 'error' : ''"
                      v-model="lv_home_addr.value1"
                      disabled
                    />
                    <mo-text-field
                      class="w61"
                      :class="lv_home_addr.error ? 'error' : ''"
                      v-model="lv_home_addr.value2"
                      disabled
                    />
                  </div>
                </td>
                <th class="bl-ddd">
                  <span> E-mail </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="email_addr1"
                      class="input-email01 input-long"
                      :class="lv_email_addr.error ? 'error' : ''"
                      placeholder="입력하세요"
                      v-model="lv_email_addr.value1"
                      :disabled="lv_email_addr.disabled"
                      @keyup.space="fn_Keyup($event,{ objName: 'lv_email_addr', keyName: 'value1' }, 'trim')"
                      maxlength="20"
                      clearable
                    />
                    <span class="ico-between-email"> @ </span>
                    <mo-text-field
                      ref="email_addr2"
                      class="input-email02 input-long"
                      :class="lv_email_addr.error ? 'error' : ''"
                      placeholder="입력하세요"
                      v-model="lv_email_addr.value2"
                      :disabled="lv_email_addr.disabled"
                      @keyup.space="fn_Keyup($event,{ objName: 'lv_email_addr', keyName: 'value2' }, 'trim')"
                      maxlength="20"
                      clearable
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소3 -->
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="lv_home_addr.value3"
                      disabled
                    />
                  </div>
                </td>
                <th rowspan="3" class="bl-ddd">
                  <span class="emphasis"> 위임내용 </span>
                </th>
                <td rowspan="3">
                  <ul class="wrap-checklist">
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.isAll"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        @click="fn_SetChk(1)"
                        >전체</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="accnOpng"
                        >계좌개설(투자자정보작성)</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="buyRpus"
                        >매입환매</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="chgApl"
                        >제변경신청</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="trsfDfra"
                        >이체/출금</mo-checkbox
                      >
                    </li>
                    <li>
                      <mo-checkbox
                        v-model="lv_agnt_trst_cntnt.value"
                        :disabled="lv_agnt_trst_cntnt.disabled"
                        value="etc"
                        >기타</mo-checkbox
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소4 -->
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="lv_home_addr.value4"
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 본인과의 관계 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="lv_commCode.code410"
                      :itemText="'cdesc1'"
                      :class="lv_agnt_rltn.error ? 'error' : ''"
                      v-model="lv_agnt_rltn.value"
                      :disabled="lv_agnt_rltn.disabled"
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 지정일 </span>
                </th>
                <td>
                  <div class="picker row">
                    <mo-date-picker
                      :class="lv_cal_asgn_dt.error ? 'error' : ''"
                      v-model="lv_cal_asgn_dt.value"                      
                      :bottom="false"
                      disabled
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 만기일 </span>
                </th>
                <td>
                  <div class="picker row">
                    <mo-date-picker
                      :class="lv_cal_exp_dt.error ? 'error' : ''"
                      v-model="lv_cal_exp_dt.value"
                      :disabled="lv_cal_exp_dt.disabled"
                      :bottom="false"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_OnDelete" :disabled="lv_btn_delete">
            삭제
          </mo-button>
          <mo-button point size="large" @click="fn_OnUpdate" :disabled="lv_btn_update">
            수정
          </mo-button>
          <mo-button primary size="large" @click="fn_OnInsert" :disabled="lv_btn_insert">
            등록
          </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->
    <msp-fs-100p
      ref="popup100"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup100Obj"
    ></msp-fs-100p>

    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj">
    </fs-alert-popup>

    <!-- popup308 -->
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSHeader from "@/ui/fs/comm/FSHeader";
import FSAlertPopup from "~/src/ui/fs/comm/FSAlertPopup"; // Alert 팝업 (공통)
import MSPFS308P from "~/src/ui/fs/MSPFS308P"; // 고객정보 팝업
import FSCommUtil from "@/ui/fs/comm/FSCommUtil";
import FSServiceUtil from "@/ui/fs/comm/FSServiceUtil";
import moment from "moment";
import DateUtil from "@/ui/sp/common/exDateUtils";
import MSPFS100P from "~/src/ui/fs/MSPFS100P"; // 우편번호 (공통)

import FSInfoUtil from "~/src/ui/fs/comm/FSInfoUtil";
import FSCodeUtil from "~/src/ui/fs/comm/FSCodeUtil";
import FSMessageUtil from '@/ui/fs/comm/FSMessageUtil'  // message util
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS302M",
  screenId: "MSPFS302M",
  components: {
    "fs-header": FSHeader,
    "fs-alert-popup": FSAlertPopup,    
    "msp-fs-308p": MSPFS308P,
    "msp-fs-100p": MSPFS100P,
  },  
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
    this.$nextTick(() => {
      if(this.$route.params.bfcer_cust_no) {        
        this.lv_ed_cust_no.value = this.$route.params.bfcer_cust_no
        this.fn_SearchValid({key:'v'})
        this.lv_isFirst = true
      }
    })    
  },
  mounted() {
    this.fn_Init()

    // let lv_Vm = this
    // this.$bizUtil.fsUtils.selBasInfo(this, false).then(function (response) {        
    //   lv_Vm.lv_basInfo = lv_Vm.getStore('fsStore').getters.getBasInfo.data
    // }).catch(function (error) {
    //   window.vue.error(error)
    // })
    
    this.$bizUtil.insSrnLog(this.$route.name)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      pHeaderObj: {
        title: "업무대리인등록/변경",
        step: '7',
      },
      //lv_basInfo: {},
      // 팝업 객체
      pPopup308Obj: {},
      pPopup100Obj: {},      

      pAlertPopupObj: {},
      pCustInfoObj: {},

      // 검색조건
      lv_ed_cust_no: {}, // 고객번호
      lv_ed_cust_nm: {}, // 고객명

      lv_cust_info: {}, // 고객정보
      lv_commCode: {}, // 공통코드

      // 대리인정보
      lv_ed_agnt_nm: {}, // 성명
      lv_cob_naty_cntry_cd: {}, // 국적
      lv_cob_agnt_rname_gb: {}, // 실명번호종류
      lv_ed_agnt_rname_no: {}, // 실명번호
      lv_ed_agnt_enm: {}, // 영문명
      lv_ed_agnt_psprt_no: {}, // 여권번호
      lv_cal_birth_dt: {}, // 생일 > 날짜
      lv_rdo_agnt_sex: {}, // 성별
      lv_masked_val: '',  // 마스크 변수 체크

      lv_cob_main_dm: {}, // 주소유형
      lv_home_addr: {}, // 자택주소
      lv_home_no: {}, // 자택전화번호
      lv_phone_no: {}, // 휴대폰번호
      lv_email_addr: {}, // email주소
      lv_agnt_trst_cntnt: {}, // 위임내용
      lv_agnt_rltn: {}, // 본인과의 관계
      lv_cal_asgn_dt: {}, // 지정일
      lv_cal_exp_dt: {}, // 만기일
      ds_list: [],       // 조회 데이터
      ds_agent: {},      // 조회 후 선택한 데이터
      lv_btn_add_agnt: true,
      lv_btn_delete: true,
      lv_btn_update: true,
      lv_btn_insert: true,
      lv_btn_status: '', // 버튼 상태
      lv_isInsert: false, // 등록,수정,삭제 확인 여부
      ds_transaction: [], // 처리 결과 데이터 (수정, 삭제, 등록 등),
      lv_isFirst: false, // 다른 화면에서 넘어왔을 경우 한번만 처리
      lv_isMobile: this.getStore('deviceState').getters.getIsMobile, // 모바일 체크
      isMtrans : this.getStore('fsStore').getters.getState.isMtrans, // 보안키피드 적용 여부
      lv_isClear: false, // 보안키패드 초기화
      lv_isRegist: false, // 기간추가 또는 등록 시
      lv_busyDate: '', // 업무일자

      /***********************************************************************************       
       * F10570167 : 개인정보조회
       * F10570181 : 대리인정보등록
       * F10570182 : 대리인정보수정
       * F10570183 : 대리인정보취소
       * F10570184 : 대리인정보조회
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {    
    gender() {
      let rtn = [];
      rtn.push({ value: "M", text: "남" });
      rtn.push({ value: "F", text: "여" });
      return rtn;    },
    
    relation() {
      let rtn = [];
      rtn.push({ value: "1", text: "배우자" });
      rtn.push({ value: "2", text: "직계비속" });
      rtn.push({ value: "3", text: "직계존속" });
      rtn.push({ value: "4", text: "법인대표" });
      rtn.push({ value: "5", text: "종업원" });
      return rtn;
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {    
    'lv_agnt_trst_cntnt.value': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            let agntChkArrAll = ['accnOpng', 'buyRpus', 'chgApl', 'trsfDfra', 'etc']            
            if (this.lv_agnt_trst_cntnt.value.filter(d => agntChkArrAll.includes(d)).length === 5) {
              this.lv_agnt_trst_cntnt.isAll = true
            } else {
              // 하나라도 포함하지 않음
              this.lv_agnt_trst_cntnt.isAll = false
            }
          } else {
            // 모두 포함하지 않음
            this.lv_agnt_trst_cntnt.isAll = false
          }
        },
        deep: true
      },
      'lv_cob_naty_cntry_cd.value': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            if (after === 'KR') {
              this.lv_ed_agnt_enm.disabled = true
              this.lv_ed_agnt_psprt_no.disabled = true
              this.lv_cal_birth_dt.disabled = true
              this.lv_rdo_agnt_sex.disabled = true
           
              this.lv_ed_agnt_enm.value = ''        //고객영문명
              this.lv_ed_agnt_psprt_no.value = ''   //여권번호
              this.lv_cal_birth_dt.value = ''       //생년월일
              this.lv_rdo_agnt_sex.value = ''       //성별
            } else {
              this.lv_ed_agnt_enm.disabled = false
              this.lv_ed_agnt_psprt_no.disabled = false
              this.lv_cal_birth_dt.disabled = false
              this.lv_rdo_agnt_sex.disabled = false
            }
          }
        },
        deep: true
      }, 
      'lv_phone_no.value1': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            this.lv_phone_no.delete = false
          } else {
            this.lv_phone_no.delete = true
          }
        },
        deep: true
      },
      'lv_phone_no.value2': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            this.lv_phone_no.delete = false
          } else {
            this.lv_phone_no.delete = true
          }
        },
        deep: true
      },
      'lv_phone_no.value3': {
        handler(after, before) {
          if( !FSCommUtil.gfn_isNull(after) ){            
            this.lv_phone_no.delete = false
          } else {
            this.lv_phone_no.delete = true
          }
        },
        deep: true
      },          
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_CheckSsnValidation
    * 설명       : 주민번호 유효성 체크
    ******************************************************************************/
    fn_CheckSsnValidation (e) {
      const pParams = {
        knb, // 전체 주민번호
        knbFrno: target.knbFrno, // 주민번호 앞번호
        knbBknoCipher: target.knbBkno, // 주민번호 뒷번호
        knbScCd: target.knbScCd // ios encrypt key
      }

      const trnstId = 'txTSSCM10S6'
      this.post(this, pParams, trnstId, 'S')
        .then(response => {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            if (response.body.ssnYn === 'Y') {
              target.knbEncr = response.body.knbEncr
              this.fnMakeParams('1')
            }
          } else {
            this.$addSnackbar(response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val            
      if(this.lv_masked_val.length >= 7) {
        console.log('fn_SetMaskedTxt >', this.lv_masked_val)
      }
    },
    /******************************************************************************
      * Function명 : fn_AlertPopup
      * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
      },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     * 파라미터(타입) : type (init - 초기화 시), flag (false - 활성화, true - 비활성화)      
     ******************************************************************************/
    fn_Init(type = 'init', flag = true) {
      console.log("[MSPFS302M] fn_Init...");      
      
      // 초기화 버튼 클릭 시, 초기화 시 검색 조건 초기화
      if(type === 'init'){
        this.lv_ed_cust_no = FSCommUtil.gfn_dataSet(1,"",false,"","ed_cust_no")  // 고객번호        
      }

      if(type === 'init' || type === 'keyup') {
        this.lv_ed_cust_nm       = FSCommUtil.gfn_dataSet(1)                     // 고객명
        this.lv_btn_status = ''
      }

      // 기간추가 가 아닐 시 초기화
      if(type !== 'add'){
        this.ds_list = []
        this.ds_agent = {}
      }

      // 업무대리인 정보
      this.lv_cob_agnt_rname_gb  = FSCommUtil.gfn_dataSet(0, '01', flag); // 실명번호 종류      
      this.lv_ed_agnt_nm         = FSCommUtil.gfn_dataSet(1, ''  , flag,'','ed_agnt_nm'); // 고객명
      this.lv_ed_agnt_enm        = FSCommUtil.gfn_dataSet(1, ''  , flag); // 고객영문명
      this.lv_cob_naty_cntry_cd  = FSCommUtil.gfn_dataSet(0, "KR", flag); // 국적
      this.lv_cal_asgn_dt        = FSCommUtil.gfn_dataSet(1, ''  , flag); // 지정일
      this.lv_cal_exp_dt         = FSCommUtil.gfn_dataSet(1, ''  , flag); // 만기일
      this.lv_cal_birth_dt       = FSCommUtil.gfn_dataSet(1, ''  , flag); // 생년월일
      this.lv_cob_main_dm        = FSCommUtil.gfn_dataSet(0, '1' , flag); // 주소유형
      this.lv_ed_agnt_psprt_no   = FSCommUtil.gfn_dataSet(1, ''  , flag); // 여권번호
      this.lv_rdo_agnt_sex       = FSCommUtil.gfn_dataSet(1, ''  , flag); // 성별
      this.lv_agnt_rltn          = FSCommUtil.gfn_dataSet(1, ''  , flag); // 본인과의 관계

      // 자택주소
      this.lv_home_addr = {
        disabled: flag,
        class: false,
        error: false,
        delete: false,
        standard: false,
        zipNum: "",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      }

      // 이메일주소
      this.lv_email_addr = {
        disabled: flag,
        class: false,
        error: false,        
        value1: "",
        value2: "",        
      }

      // 자택전화번호
      this.lv_home_no = {
        disabled: flag,
        class: false,
        error: false,        
        value1: "",
        value2: "",
        value3: "",
      }

      // 휴대폰번호
      this.lv_phone_no = {
        disabled: flag,
        class: false,
        error: false,
        delete: flag,
        value1: "",
        value2: "",
        value3: "",
      }      

      // 위임내용
      this.lv_agnt_trst_cntnt = {
        disabled: flag,
        value: [],
        isAll: false,
      }

      // 실명번호
      this.lv_ed_agnt_rname_no = {
        disabled: flag,
        class: false,
        error1: false,
        error2: false,
        value1: "",
        value2: "",        
      }

      this.lv_cust_info = {}      
      // this.lv_btn_delete = true
      // this.lv_btn_update = true
      // this.lv_btn_insert = true
      //this.lv_btn_add_agnt = true

      // 보안키패드 초기화
      this.lv_isClear = !this.lv_isClear ? true : false

      // 등록 시
      this.lv_isRegist = false 
    },
    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event) {
      console.log("fn_SearchValid.....");
      if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7) {
        this.lv_ed_cust_no.error = false;

        if (!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value)) {
          FSCommUtil.gfn_validate(this, "고객번호 형식에 맞지 않습니다.");
          this.lv_ed_cust_no.error = true;
          this.$refs["ed_cust_no"].focus();
          return;
        } else {
          if (FSCommUtil.gfn_isNum(event.key) || event.key == "v") {
            // 고객정보조회
            this.fn_CustInfoSearch();
          }
        }
      } else {
        this.fn_Init('keyup')
      }
    },

    /******************************************************************************
     * Function명 : fn_CustInfoSearch, fn_CustInfoSearchResult
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfoSearch() {
      console.log("fn_CustInfoSearch.....");

      let t_data = { bfcer_cust_no: this.lv_ed_cust_no.value };
      FSInfoUtil.commInfo(this, 0, t_data, this.fn_CustInfoSearchResult);
    },

    fn_CustInfoSearchResult(pResultData) {
      console.log("fn_CustInfoSearchResult.....");

      // 초기화
      this.fn_Init();

      let t_data = pResultData.data.sy001000_O_00VO;

      if (t_data.length > 0) {
        this.lv_ed_cust_nm.value = t_data[0].fnd_cust_nm;
        this.lv_ed_cust_no.value = t_data[0].bfcer_cust_no;

        // 실명번호
        // this.lv_cust_info.rlnm_cnf_no_encr =
        //   FSCommUtil.gfn_substr(t_data[0].rlnm_cnf_no_encr, 0, 6) +
        //   "-" +
        //   FSCommUtil.gfn_substr(t_data[0].rlnm_cnf_no_encr, 6, 7);

        this.lv_cust_info.rlnm_cnf_no_encr = t_data[0].rlnm_cnf_no_encr

        // 핸드폰번호
        this.lv_cust_info.phone_no = FSCommUtil.gfn_isNull(
          t_data[0].bman_celph_dstno_encr
        )
          ? ""
          : t_data[0].bman_celph_dstno_encr +
            "-" +
            t_data[0].bman_celph_tlnum_no_encr +
            "-" +
            t_data[0].bman_celph_bkno_sno_encr;

        // 투자성향
        this.lv_cust_info.invst_dispos = t_data[0].invst_dispos;
        this.lv_cust_info.expd_stnd_ymd =
          "(" + t_data[0].expd_stnd_ymd + "~" + t_data[0].expd_exp_ymd + ")";

        // 집전화
        this.lv_cust_info.hom_no = FSCommUtil.gfn_isNull(
          t_data[0].hom_dstno_encr
        )
          ? ""
          : t_data[0].hom_dstno_encr +
            "-" +
            t_data[0].hom_tlnum_no_encr +
            "-" +
            t_data[0].hom_bkno_sno_encr;

        // 회사전화
        this.lv_cust_info.job_no = FSCommUtil.gfn_isNull(
          t_data[0].jobp_dstno_encr
        )
          ? ""
          : t_data[0].jobp_dstno_encr +
            "-" +
            t_data[0].jobp_tlnum_no_encr +
            "-" +
            t_data[0].jobp_bkno_sno_encr;

        // 대리인 성명
        this.lv_cust_info.agent_nm = t_data[0].agent_nm;

        // 대리인 회사전화
        this.lv_cust_info.agent_job_no = FSCommUtil.gfn_isNull(
          t_data[0].agnt_jobp_dstno_encr
        )
          ? ""
          : t_data[0].agnt_jobp_dstno_encr +
            "-" +
            t_data[0].agnt_jobp_tlnum_no_encr +
            "-" +
            t_data[0].agnt_jobp_bkno_sno_encr;

        // 대리인 핸드폰번호
        // this.lv_cust_info.agent_phone_no   = FSCommUtil.gfn_isNull(t_data[0].jobp_dstno_encr) ? '' : t_data[0].jobp_dstno_encr + '-' + t_data[0].agnt_celph_tlnum_no_encr + '-' + t_data[0].agnt_celph_bkno_sno_encr

        // 본인과의 관계
        this.lv_cust_info.agent_rel_gb_nm = t_data[0].agent_rel_gb_nm;

        // 대리인 유효기간
        // this.lv_cust_info.agent_nm           = t_data[0].agent_nm

        // 특이사항
        this.lv_cust_info.cust_bas_note = t_data[0].cust_bas_note;

        if(this.lv_isFirst){
          this.fn_SearchList()
          this.lv_isFirst = false
        }
      } else {
        this.fn_AlertPopup(0,{content: '고객이 존재하지 않습니다.'})
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 고객정보 1: 우편번호
     ******************************************************************************/
    fn_OpenPopup(type, flag) {
      let t_popupObj = {}
      switch (type) {
        case 0:          
          this.$refs.popup308.fn_Open();          
          break
        case 1:
          this.pPopup100Obj.callbackType = type
          this.pPopup100Obj.btnFlag = flag

          this.$refs.popup100.fn_Open()
          break;
        case 2:
          break;
      }
    },

    /******************************************************************************
     * Function명 : fn_OnSearch
     * 설명       : 1차 확인(조회)
     ******************************************************************************/
    fn_OnSearch() {
      // 실명번호 체크
      // if (!this.fn_com_res_chk()) {
      //   return;
      // }

      // // 실명번호종류 체크
      // if (!this.fn_rname_chk()) {
      //   return;
      // }

      // 실명번호종류가 주민번호일 경우 체크
      // if (this.lv_cob_agnt_rname_gb.value == "01") {
      //   this.fn_SetCalBirthDt();
      // }

      // 개인고객정보조회
      this.fn_CustSearch();

      // 대리인목록조회
      // this.fn_AgntSearch();      
    },

    /******************************************************************************
     * Function명 : fn_OnSearchList
     * 설명       : 대리인 기간별 조회 이벤트
     ******************************************************************************/
    fn_OnSearchList() {
      if( FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) ) {
        let t_popupObj = {content: '고객번호를 입력하세요.'}
        this.fn_AlertPopup(0, t_popupObj)
        return
      }

      this.fn_Init('search')     
      

      // 대리인기간별 조회
      this.fn_SearchList()
    },
    /******************************************************************************
     * Function명 : fn_com_res_chk
     * 설명       : 실명번호 체크(공통)
     ******************************************************************************/
    fn_com_res_chk() {
      this.lv_cob_agnt_rname_gb.error = false;
      this.lv_ed_agnt_rname_no.error1 = false;  //실명번호 앞자리
      this.lv_ed_agnt_rname_no.error2 = false;  //실명번호 뒷자리

      if (
        FSCommUtil.gfn_length(this.lv_ed_agnt_rname_no.value1) != 6 ||
        !FSCommUtil.gfn_isNum(this.lv_ed_agnt_rname_no.value1)
      ) {
        FSCommUtil.gfn_validate(this, "실명번호의 형식에 맞지 않습니다.");
        this.lv_ed_agnt_rname_no.error1 = true;
        this.$refs["ed_rname_no1"].focus();
        return false;
      }

      if(!this.lv_isMobile){
        if(FSCommUtil.gfn_length(this.lv_ed_agnt_rname_no.value2) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_agnt_rname_no.value2)) {
          FSCommUtil.gfn_validate(this, "실명번호의 형식에 맞지 않습니다.");
          this.lv_ed_agnt_rname_no.error2 = true;
          this.$refs["ed_rname_no2"].focus();
          return false;
        }
      } else {
        if(this.lv_masked_val.length != 7) {
          FSCommUtil.gfn_validate(this, "실명번호의 형식에 맞지 않습니다.");
          this.lv_ed_agnt_rname_no.error2 = true;
          this.$refs["ed_rname_no2"].focus();
          return false;
        }
      }
      return true;
    },

    /******************************************************************************
     * Function명 : fn_rname_chk
     * 설명       : 실명번호종류 체크(공통)
     ******************************************************************************/
    fn_rname_chk() {
      // 주민등록번호
      if (this.lv_cob_agnt_rname_gb.value == "01") {
        this.lv_cob_inc_earner_gb.value = "111"; // 소득자 구분

        // 외국인등록번호
      } else if (this.lv_cob_agnt_rname_gb.value == "04") {
        this.lv_cob_inc_earner_gb.value = "131"; // 소득자 구분
      }
      return true;
    },

    /******************************************************************************
     * Function명 : fn_SetCalBirthDt
     * 설명       : 실명번호종류가 주민번호일 경우 생일 세팅
     ******************************************************************************/
    fn_SetCalBirthDt () {
      let t_birthDt = null
      let t_year = 19
      let t_date = this.lv_ed_agnt_rname_no.value1

      // 주민번호가 3이상이면 2000년도 출생
      if(!this.lv_isMobile){
        if (Number(FSCommUtil.gfn_substr(this.lv_ed_agnt_rname_no.value2, 0, 1)) > 2) {
          t_year = 20
        }
      } else {
        if (Number(FSCommUtil.gfn_substr(this.lv_masked_val, 0, 1)) > 2) {
          t_year = 20
        }
      }

      t_birthDt = t_year + t_date
      if( !FSCommUtil.gfn_isNull(t_birthDt) ) {
        this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, t_birthDt)
      }
    },

    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log("fn_SetCommCode...");

      let params = [
        { cId: "211", dayChkYn: "N" },
        { cId: "981", dayChkYn: "N" },
        { cId: "410", dayChkYn: "N" },
        { cId: "370", dayChkYn: "N" },
        { cId: "215", dayChkYn: "N" },
      ];
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult);
    },

    fn_SetCommCodeResult(pResult) {
      
      this.lv_commCode = pResult;

      this.lv_commCode.code370.push({value: '' , text: ''})

      this.lv_commCode.code410 = pResult.code410.filter(d => d.cdesc1 !== '') //본인과의 관계 (text : 비고1 컬럼 사용)
    },

    /***************************************************************************************
     * Function명 : fn_Keyup
     * 설명       : keyup
     * flag      : trim (공백제거) / del (제거)
    *****************************************************************************************/
    fn_Keyup (event, pData, flag) {
      const t_objName   = pData.objName
      const t_keyName = pData.keyName

      if( FSCommUtil.gfn_isNull(flag) ) {
        // flag 없을 경우 처리

      } else {
        if( flag == 'trim' ) {
          FSCommUtil.gfn_validate(this, '공백입력은 허용되지 않습니다. 작성에 주의하시기 바랍니다.')
          this[t_objName][t_keyName] = FSCommUtil.gfn_trim(this[t_objName][t_keyName])
          
        } else if( flag == 'del' ) {
          this[t_objName][t_keyName] = ''
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_DeletePhoneNo
     * 설명       : 휴대번호 번호 삭제
     ******************************************************************************/
    fn_DeletePhoneNo() {
      // 휴대폰번호
      this.lv_phone_no = {
        disabled: false,        
        error: false,
        delete: true,
        value1: "",
        value2: "",
        value3: "",
      }
    },

    /*********************************************************
     * Function명: fn_SetChk
     * 설명: 위임내용 체크 이벤트
     * 파라미터 : type ( 1: all 체크 )
     *********************************************************/
    fn_SetChk(type) {
      switch(type){
        case 1:
          let tmpChk = Object.assign({}, this.lv_agnt_trst_cntnt);
          if (!tmpChk.isAll) {
            if (!tmpChk.value.includes("accnOpng")) {
              tmpChk.value.push("accnOpng");
            }

            if (!tmpChk.value.includes("buyRpus")) {
              tmpChk.value.push("buyRpus");
            }

            if (!tmpChk.value.includes("chgApl")) {
              tmpChk.value.push("chgApl");
            }

            if (!tmpChk.value.includes("trsfDfra")) {
              tmpChk.value.push("trsfDfra");
            }

            if (!tmpChk.value.includes("etc")) {
              tmpChk.value.push("etc");
            }
          } else {
            tmpChk.value = [];
          }

          this.lv_agnt_trst_cntnt = tmpChk;        
          break
      }      
    },

    /*********************************************************
     * Function명: fn_GetChk
     * 설명: 위임내용 체크 가져오기
     * 파라미터 : type ( 1: 조회 후 설정, 2: 등록/수정 시 설정 ), val (체크 데이터)
     *********************************************************/
    fn_GetChk(type, val = '') {
      let tmpChk = null
      switch(type){
        case 1:
          tmpChk = []
          if(FSCommUtil.gfn_isNull(val)){
            return tmpChk
          }

          let chkVal = FSCommUtil.gfn_trim(val)
          if(chkVal.length !== 5){
            return tmpChk
          }
          
          if(chkVal.substr(0,1) === 'Y'){
            tmpChk.push("accnOpng")
          }

          if(chkVal.substr(1,1) === 'Y'){
            tmpChk.push("buyRpus")
          }

          if(chkVal.substr(2,1) === 'Y'){
            tmpChk.push("chgApl")
          }

          if(chkVal.substr(3,1) === 'Y'){
            tmpChk.push("trsfDfra")
          }

          if(chkVal.substr(4,1) === 'Y'){
            tmpChk.push("etc")
          }          
          break
        case 2:
          tmpChk = ''
          let tmpCurChk = Object.assign([], this.lv_agnt_trst_cntnt.value)
          if(tmpCurChk.includes('accnOpng')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }

          if(tmpCurChk.includes('buyRpus')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }

          if(tmpCurChk.includes('chgApl')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }

          if(tmpCurChk.includes('trsfDfra')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }

          if(tmpCurChk.includes('etc')){
            tmpChk += 'Y'
          }else{
            tmpChk += 'N'
          }
          break
      }
      return tmpChk      
    },


     /******************************************************************************
     * Function명 : fn_CustSearch, fn_CustSearchResult
     * 설명       : 개인고객정보조회
     ******************************************************************************/
    fn_CustSearch () {
      console.log('fn_CustSearch...')

      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSFS11S1'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570118_S'
      this.eaiCommObj.params = {
        bfcer_incmr_sc_cd     : this.lv_cob_inc_earner_gb.value, // 수익증권소득자구분코드, Length : 3
        bfcer_rlnm_no_knd_cd  : this.lv_cob_agnt_rname_gb.value, // 수익증권실명번호종류코드, Length : 2          
        rlnm_cnf_no_encr      : this.lv_ed_agnt_rname_no.value1 + this.lv_ed_agnt_rname_no.value2,  // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustSearchResult)
    },

    fn_CustSearchResult (pResultData, pErrorCode) {
        console.log('fn_CustSearchResult...')
        let t_data = pResultData.data.cu100000_O_01VO
        console.log(t_data)

        if (t_data.length > 0) {
        }
    },

    /******************************************************************************
     * Function명 : fn_SearchList, fn_SearchListResult
     * 설명       : 업무대리인 기간별 조회
     * svcId     : selectSI410000_custList     
     ******************************************************************************/
    fn_SearchList (type = 'S') {
      console.log('fn_SearchList...')

      if(type === 'S'){
        this.lv_isInsert = false
      } else {
        this.lv_isInsert = true
      }

      this.eaiCommObj.lv_vm = this      
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570180_S'
      this.eaiCommObj.params = {
        bfcer_cust_no     : this.lv_ed_cust_no.value,
        proc_gb           : 'S'  // 영업점코드
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchListResult)
    },

    fn_SearchListResult (pResultData) {
      console.log('fn_SearchListResult...', pResultData)
      let t_data = pResultData.data.si410000_O_00VO
      if(pResultData.data.error_msg !== '809900' && pResultData.data.error_msg !== '809990'){
        let t_popupObj = {content: FSMessageUtil.commMessage([])[pResultData.data.error_msg]}
        this.fn_AlertPopup(0, t_popupObj)
        return
      } else {
        // 기존 대리인 목록 구분
        if (t_data.length > 0) {
          this.fn_Init('search')
          this.ds_list = t_data                
          this.lv_btn_add_agnt = false  // 기간추가 버튼 활성화          
        } else {
          this.fn_Init('search', false) // 대리인 입력 활성화
          this.lv_btn_insert = false    // 등록 버튼 활성화
          this.lv_btn_delete = true     // 삭제 버튼 비활성화 
          this.lv_btn_update = true     // 수정 버튼 비활성화
          let tmpAddYear = DateUtil.fn_AddMonth(moment(new Date()).subtract(1, "days").format("YYYYMMDD"), 12)
          this.lv_cal_asgn_dt.value = moment(new Date().toISOString()).format("YYYY-MM-DD")
          this.lv_cal_exp_dt.value = moment(tmpAddYear).format("YYYY-MM-DD")
          this.lv_isRegist = true // 최초등록 시

          // 대리인 성명 포커스 이동
          // this.$nextTick(() => {
          //   this.$refs['ed_agnt_nm'].focus()
          // })
        }

        this.fn_NextPage(this.lv_btn_status)
      }      
    },
    
    /******************************************************************************
     * Function명 : fn_OnSelect
     * 설명       : 기간별 내역에서 대리인 선택 이벤트 (대리인 조회)
     * 파라미터    : 목록에서 선택한 데이터
     * svcId      : selectSI410000_agentList.do
     ******************************************************************************/
    fn_OnSelect(data) {
      console.log("fn_OnSelect.....", data)

      let lv_Vm = this;

      this.lv_cal_exp_dt.disabled = false      // 만기일 활성화
      
      this.lv_busyDate = this.getStore('fsStore').getters.getBasInfo.data.busyDate

      if (data.reg_ymd === this.lv_busyDate) {
         this.lv_btn_delete = false            // 삭제 버튼 활성화
         this.lv_btn_update = false            // 수정 버튼 활성화
      } else {	
         this.lv_btn_delete = true             // 삭제 버튼 비활성화
         this.lv_btn_update = true             // 수정 버튼 비활성화
      }

      lv_Vm.eaiCommObj.lv_Vm = this;
      lv_Vm.eaiCommObj.auth = "S";

      lv_Vm.eaiCommObj.commHeaderVO.eaiId = "C391_F10570184_S";
      lv_Vm.eaiCommObj.params = {
        bfcer_cust_no: this.lv_ed_cust_no.value,
        agnt_sno: data.agnt_sno,
        asgn_ymd: data.asgn_ymd,
        exp_ymd: data.exp_ymd,
        reg_ymd: data.reg_ymd
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_OnSelectResult)
    },
    fn_OnSelectResult(pResultData){
      console.log("fn_OnSelectResult.....", pResultData)            
      let t_data = pResultData.data.tfoc02095_O_VO
      if(pResultData.data.error_msg !== '809900'){
        let t_popupObj = {content: FSMessageUtil.commMessage([])[pResultData.data.error_msg]}
        this.fn_AlertPopup(0, t_popupObj)
        return
      } else {
        if (t_data.length > 0) {
          this.ds_agent = t_data[0]
          let agntInfo = t_data[0] //변경여부를 비교를위해 copy        
          this.fn_SetData(1, agntInfo)
          if (agntInfo.agnt_naty_cntry_cd === 'KR') {
            this.lv_ed_agnt_enm.disabled = true
            this.lv_ed_agnt_psprt_no.disabled = true
            this.lv_cal_birth_dt.disabled = true
            this.lv_rdo_agnt_sex.disabled = true
          } else {
            this.lv_ed_agnt_enm.disabled = false
            this.lv_ed_agnt_psprt_no.disabled = false
            this.lv_cal_birth_dt.disabled = false
            this.lv_rdo_agnt_sex.disabled = false
          }

          // this.lv_btn_delete = false            // 삭제 버튼 활성화
          // this.lv_btn_update = false            // 수정 버튼 활성화
          
          if (agntInfo.reg_ymd === this.lv_busyDate){
            this.lv_btn_delete = false            // 삭제 버튼 활성화
            this.lv_btn_update = false            // 수정 버튼 활성화  
            
            this.lv_cal_exp_dt.disabled = true    // 만기일 활성화
          } else {
            this.lv_btn_delete = true             // 삭제 버튼 비활성화
            this.lv_btn_update = true             // 수정 버튼 비활성화 
          }

          this.lv_btn_insert = true               // 등록 버튼 비활성화
          //this.lv_cal_exp_dt.disabled = false              // 만기일 활성화
        } 
      }
    },

    /******************************************************************************
     * Function명 : fn_OnAddAgnt
     * 설명       : 기간추가 버튼 이벤트
     ******************************************************************************/
    fn_OnAddAgnt() {
      this.fn_Init('add', false)       // 대리인 입력 내용 활성화            
      this.lv_btn_delete = true        // 삭제 버튼 비활성화
      this.lv_btn_update = true        // 수정 버튼 비활성화
      this.lv_btn_insert = false       // 등록 버튼 활성화

      let tmpExpYmd = new Date()
      // if(!FSCommUtil.gfn_isNull(this.ds_list) && this.ds_list.length > 0) {
      //   let _orderBy = require('lodash/orderBy')
      //   let tmpData = _orderBy(this.ds_list, 'exp_ymd', 'desc')[0]
      //   if(!FSCommUtil.gfn_isNull(tmpData)){
      //     tmpExpYmd = moment(tmpData.exp_ymd).add(1,"days").format("YYYYMMDD")
      //   }
      // }
      //지정일은 무조건 당일로 지정되야 합니다. jira 반영
      tmpExpYmd = moment(tmpExpYmd).format("YYYYMMDD")
      
      let tmpAddYear = DateUtil.fn_AddMonth(moment(tmpExpYmd).subtract(1, "days").format("YYYYMMDD"), 12)
      this.lv_cal_asgn_dt.value = moment(tmpExpYmd).format("YYYY-MM-DD")
      this.lv_cal_exp_dt.value = moment(tmpAddYear).format("YYYY-MM-DD")
      
      this.$refs['ed_agnt_nm'].focus()
      this.lv_isRegist = true
    },
    /******************************************************************************
     * Function명 : fn_SetData
     * 설명       : 호출 결과 데이터 설정
     * 파라미터    : type (1 : 조회) , data (호출 결과 데이터)                 
     ******************************************************************************/
    async fn_SetData(type, data = {}){
      switch(type){
        case 1: // 대리인 정보
          // 성명
          this.lv_ed_agnt_nm.value = data.bfcer_agnt_nm
          this.lv_ed_agnt_nm.disabled = false

          // 국적
          this.lv_cob_naty_cntry_cd.value = data.agnt_naty_cntry_cd
          this.lv_cob_naty_cntry_cd.disabled = false

          // 실명번호 종류
          this.lv_cob_agnt_rname_gb.value = data.agnt_rlnm_no_knd_cd
          this.lv_cob_agnt_rname_gb.disabled = false

          // 실명번호종류가 주민번호일 경우 체크
          // if (this.lv_cob_agnt_rname_gb.value == "01") {
          //   this.fn_SetCalBirthDt();
          // }

          // 실명번호
          this.lv_ed_agnt_rname_no.value = data.agnt_rlnm_cnf_no_encr

          if (this.lv_ed_agnt_rname_no.value.length > 6) {
            this.lv_ed_agnt_rname_no.value1 = this.lv_ed_agnt_rname_no.value.substring(0,6)
            this.lv_ed_agnt_rname_no.value2 = this.lv_ed_agnt_rname_no.value.substring(6)
          }

          this.lv_ed_agnt_rname_no.disabled = false

          // 영문명
          this.lv_ed_agnt_enm.value = data.agnt_eng_nm          
          this.lv_ed_agnt_enm.disabled = false

          // 여권번호
          this.lv_ed_agnt_psprt_no.value = data.agnt_psprt_no_encr          
          this.lv_ed_agnt_psprt_no.disabled = false

          // 생년월일
          if(!FSCommUtil.gfn_isNull(data.agnt_birth_mmdd)){
            this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, data.agnt_birth_mmdd)    
            this.lv_cal_birth_dt.disabled = false
          }

          // 성별
          this.lv_rdo_agnt_sex.value = data.agnt_sxds_cd
          this.lv_rdo_agnt_sex.disabled = false

          // 주소 유형
          this.lv_cob_main_dm.value = data.bfcer_agnt_mn_ctadr_cd
          this.lv_cob_main_dm.disabled = false

          // 우편번호
          this.lv_home_addr.zipNum = data.agnt_addr_pstcd01 + data.agnt_addr_pstcd02

          // 주소
          this.lv_home_addr.value1 = data.agnt_pstcd_addr     // 본주소
          this.lv_home_addr.value2 = data.agnt_bldg_no_encr   // 건물번호
          this.lv_home_addr.value3 = data.agnt_dtlad_encr     // 상세주소
          this.lv_home_addr.value4 = data.agnt_adtn_addr_encr // 부가주소
          this.lv_home_addr.disabled = false

          // 전화번호
          this.lv_home_no.value1 = data.agnt_dstno_encr
          this.lv_home_no.value2 = data.agnt_tlnum_no_encr
          this.lv_home_no.value3 = data.agnt_bkno_sno_encr
          this.lv_home_no.disabled = false
          
          // 휴대폰번호
          this.lv_phone_no.value1 = data.agnt_celph_bman_no_encr
          this.lv_phone_no.value2 = data.agnt_celph_tlnum_no_encr
          this.lv_phone_no.value3 = data.agnt_celph_bkno_sno_encr
          this.lv_phone_no.disabled = false

          // 이메일
          this.lv_email_addr.value1 = data.agnt_emai_addr_encr
          this.lv_email_addr.value2 = data.agnt_emai_dm_nm          
          this.lv_email_addr.disabled = false

          // 본인과의 관계
          this.lv_agnt_rltn.value = data.agnt_rltn_cd
          this.lv_agnt_rltn.disabled = false

          // 위임내용
          this.lv_agnt_trst_cntnt.value = this.fn_GetChk(1, data.trst_sc_list_cntnt)
          this.lv_agnt_trst_cntnt.disabled = false

          // 지정일
          this.lv_cal_asgn_dt.value = FSCommUtil.gfn_dateReplace(1, data.asgn_ymd)
          this.lv_cal_asgn_dt.disabled = false

          // 만기일
          this.lv_cal_exp_dt.value = FSCommUtil.gfn_dateReplace(1, data.exp_ymd)
          this.lv_cal_exp_dt.disabled = false
          break        
      }
    },
    /******************************************************************************
     * Function명 : fn_GetData
     * 설명       : 데이터 설정
     * 파라미터    : type (1 : 수정)
     ******************************************************************************/
    fn_GetData(type){
      let rtn = {}
      switch (type){
        case 1:
          rtn.bfcer_cust_no =  this.lv_ed_cust_no.value          
          rtn.agnt_addr_pstcd01 = this.lv_home_addr.zipNum.substr(0,3)
          rtn.agnt_addr_pstcd02 = this.lv_home_addr.zipNum.substr(2,2)
          rtn.agnt_addr_pstcd03 = ''
          rtn.agnt_addr_stdzt_yn = '' // 주소표준화여부                    
          rtn.agnt_birth_mmdd = this.lv_cal_birth_dt.value.replace(/-/g, '')          
          rtn.agnt_celph_bkno_sno_encr = 
          rtn.agnt_celph_bman_no_encr = this.lv_phone_no.value1
          rtn.agnt_celph_tlnum_no_encr = this.lv_phone_no.value2
          rtn.agnt_celph_bkno_sno_encr = this.lv_phone_no.value3          
          rtn.agnt_emai_addr_encr = this.lv_email_addr.value1
          rtn.agnt_emai_dm_nm = this.lv_email_addr.value2
          rtn.agnt_eng_nm = this.lv_ed_agnt_enm.value
          rtn.agnt_naty_cntry_cd = this.lv_cob_naty_cntry_cd.value
          rtn.agnt_psprt_no_encr = this.lv_ed_agnt_psprt_no.value
          rtn.agnt_pstcd_addr = this.lv_home_addr.value1
          rtn.agnt_bldg_no_encr = this.lv_home_addr.value2
          rtn.agnt_dtlad_encr = this.lv_home_addr.value3
          rtn.agnt_adtn_addr_encr = this.lv_home_addr.value4
          rtn.agnt_dstno_encr = this.lv_home_no.value1
          rtn.agnt_tlnum_no_encr = this.lv_home_no.value2
          rtn.agnt_bkno_sno_encr = this.lv_home_no.value3
          rtn.agnt_rlnm_cnf_no_encr = this.lv_ed_agnt_rname_no.value1 + this.lv_ed_agnt_rname_no.value2
          rtn.agnt_rlnm_no_knd_cd = this.lv_cob_agnt_rname_gb.value
          rtn.agnt_rltn_cd = this.lv_agnt_rltn.value
          rtn.agnt_sno = this.ds_agent.agnt_sno // 선택한 대리인 일련번호
          rtn.agnt_sxds_cd = this.lv_rdo_agnt_sex.value          
          rtn.asgn_ymd = this.lv_cal_asgn_dt.value.replace(/-/g, '')
          rtn.bfcer_agnt_mn_ctadr_cd = this.lv_cob_main_dm.value
          rtn.bfcer_agnt_nm = this.lv_ed_agnt_nm.value          
          rtn.cbl_yn = '' // 유선여부
          rtn.exp_ymd = this.lv_cal_exp_dt.value.replace(/-/g, '')
          rtn.reg_ymd = moment(new Date().toISOString()).format("YYYYMMDD") // 등록일
          rtn.trst_sc_list_cntnt = this.fn_GetChk(2)          
          break
      }      
      return rtn
    },    
    /******************************************************************************
     * Function명 : fn_OnInsert, fn_Insert, fn_InsertResult
     * 설명       : 입력 이벤트
     * svcId      : svcIdinsertSI410000_agent
     ******************************************************************************/
    fn_OnInsert() {
      let lv_Vm = this
      if (!lv_Vm.fn_Validate()) return      

      lv_Vm.pAlertPopupObj.confirm = true
      lv_Vm.pAlertPopupObj.confirmFunc = lv_Vm.fn_Insert
      lv_Vm.pAlertPopupObj.content = "등록하시겠습니까?"
      lv_Vm.$refs.alertPopup.fn_Open()
    },
    fn_Insert() {
      console.log('fn_Insert >')
      let lv_Vm = this
      lv_Vm.ds_transaction = [] // 처리 결과값 초기화

      lv_Vm.lv_btn_status = 'I'
      lv_Vm.eaiCommObj.lv_Vm = this      
      lv_Vm.eaiCommObj.auth = "I"
      lv_Vm.eaiCommObj.commHeaderVO.eaiId = "C391_F10570181_S"
      lv_Vm.eaiCommObj.params = lv_Vm.fn_GetData(1)            
      FSServiceUtil.invokeInDirect(lv_Vm.eaiCommObj, lv_Vm.fn_InsertResult)
    },    
    fn_InsertResult(pResultData) {
      console.log("fn_InsertResult.....", pResultData)
      let t_data = pResultData.data

      if (t_data !== null && t_data !== ''){
        if( t_data.error_msg == '809900' || FSCommUtil.gfn_trim(t_data.error_msg) == '') {
          this.ds_transaction = t_data.si410000_O_00VO
          this.fn_SearchList('I')
        } else {
          let t_popupObj = {content: FSMessageUtil.commMessage([])[t_data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)
        }
      }

      // if(pResultData.data.error_msg !== '809900'){
      //   this.fn_AlertPopup(0,{content: '정상적으로 조회되지 않았습니다.'})
      //   return
      // }
      // if (t_data.length > 0) {
      //   this.ds_agent = t_data[0]
      //   let agntInfo = t_data[0]
      //   this.fn_SetData(1, agntInfo)

      //   this.lv_btn_insert = true
      //   this.lv_btn_update = false
      //   this.lv_btn_delete = false
        
      //   console.log(t_data)
      // } else {
      //   this.fn_AlertPopup(0,{content: '고객이 존재하지 않습니다.'})
      // }
      // FSServiceUtil.invokeInDirect(
      //   lv_Vm.eaiCommObj,
      //   function (response) {
      //     if (response && response.data) {
      //       //ds_FormReportVO=reportVO
      //       if (response.data.custSrchRecList.length <= 0) {
      //         window.vue
      //           .getStore("toast")
      //           .dispatch("ADD", "고객이 존재하지 않습니다");
      //         return;
      //       } else {
      //         let responseList = response.data.custSrchRecList;

      //         lv_Vm.fn_NextPage(1);
      //         //lv_Vm.getStore('toast').dispatch('ADD', "등록되었습니다.")
      //         // trace("call insert SR chkAttorey if end ");
      //         // this.fn_ReportData();		//"0094", "개인신용정보동의서(업무대리인)"
      //       }
      //     }
      //   },
      //   function (error) {
      //     return;
      //   }
      // );
    },
    /******************************************************************************
     * Function명 : fn_OnUpdate, fn_Update, fn_UpdateResult
     * 설명       : 수정 이벤트
     * svcId      : updateSI410000_agent
     ******************************************************************************/
    fn_OnUpdate() {      
      let lv_Vm = this
      
      if (!lv_Vm.fn_Validate()) return
      if (!lv_Vm.fn_ValidateChange()) {
        this.fn_AlertPopup(0, {content: '수정사항이 없습니다.'})
        return // 수정된 부분이 있는지 체크
      }

      let t_popupObj = {
        confirm: true,
        confirmFunc: lv_Vm.fn_Update,
        content: '수정하시겠습니까?'
      }
      lv_Vm.fn_AlertPopup(0, t_popupObj)
    },
    fn_Update() {
      console.log('fn_Update >')
      this.lv_btn_status = 'U'
      
      let lv_Vm = this
      lv_Vm.ds_transaction = [] // 처리 결과값 초기화
      lv_Vm.eaiCommObj.lv_Vm = this      
      lv_Vm.eaiCommObj.auth = "S"
      lv_Vm.eaiCommObj.commHeaderVO.eaiId = "C391_F10570182_S"      
      lv_Vm.eaiCommObj.params = this.fn_GetData(1) 
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_UpdateResult)
    },

    fn_UpdateResult(pResultData){
      console.log("fn_UpdateResult.....", pResultData)
      let t_data = pResultData.data

      if (t_data !== null && t_data !== ''){
        if( t_data.error_msg == '809900' || FSCommUtil.gfn_trim(t_data.error_msg) == '') {
          this.ds_transaction = t_data.si410000_O_00VO
          this.fn_SearchList('U')
        } else {
          let t_popupObj = {content: FSMessageUtil.commMessage([])[t_data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)
        }        
      }
    },

    /******************************************************************************
     * Function명 : fn_OnDelete, fn_Delete, fn_DeleteResult
     * 설명       : 삭제 이벤트
     * svcId      : deleteSI410000_agent
     ******************************************************************************/
    fn_OnDelete() {      
      let lv_Vm = this
      lv_Vm.pAlertPopupObj.confirm = true
      lv_Vm.pAlertPopupObj.confirmFunc = lv_Vm.fn_Delete;
      lv_Vm.pAlertPopupObj.content = "삭제하시겠습니까?";
      lv_Vm.$refs.alertPopup.fn_Open()
    },
    fn_Delete() {
      console.log('fn_Delete >')
      this.lv_btn_status = 'D'

      this.lv_btn_delete = true // 삭제 버튼 비활성화
      this.lv_btn_update = true // 수정 버튼 비활성화
      
      let lv_Vm = this
      lv_Vm.ds_transaction = [] // 처리 결과값 초기화
      lv_Vm.eaiCommObj.lv_Vm = this      
      lv_Vm.eaiCommObj.auth = "S"
      lv_Vm.eaiCommObj.commHeaderVO.eaiId = "C391_F10570183_S"
      lv_Vm.eaiCommObj.params = this.fn_GetData(1)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_DeleteResult)
    },
    fn_DeleteResult(pResultData){
      console.log("fn_DeleteResult.....", pResultData)            
      let t_data = pResultData.data

      if(t_data !== null && t_data !== '' ) {
        if( t_data.error_msg == '809900' || FSCommUtil.gfn_trim(t_data.error_msg) == '' || FSCommUtil.gfn_trim(t_data.error_msg) == '1') {
          // this.$bizUtil.fsUtils.saveCvrPblInfo(this, t_data)
          this.ds_transaction = t_data.si410000_O_00VO
          this.ds_list = []
          this.ds_agent = {}      
          this.fn_SearchList('D')
        } else {
          let t_popupObj = {content: FSMessageUtil.commMessage([])[t_data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_ValidateChange
     * 설명       : 수정시 수정사항 점검
     ******************************************************************************/
    fn_ValidateChange() {
      let data = this.fn_GetData(1)      
      let count = 0
      for (const item in this.ds_agent){
        //if((this.ds_agent[item] !== data[item]) && !FSCommUtil.gfn_isNull(this.ds_agent[item]) && !FSCommUtil.gfn_isNull(data[item])) {
        if(this.ds_agent[item] !== data[item]) {
          console.log(item, this.ds_agent[item],  data[item])
          count ++
        }

      }     
      return count === 0 ? false : true
    },
    /******************************************************************************
     * Function명 : fn_Validate
     * 설명       : 등록/수정 시 입력값 검증
     ******************************************************************************/
    fn_Validate() {      
      
      // 등록 시에는 조회 할 내용이 없으므로, 해당 내용 체크 제외
      if(FSCommUtil.gfn_isNull(this.ds_agent) && !this.lv_isRegist) {
        FSCommUtil.gfn_validate(this, "저장할 데이터가 없습니다. 먼저 조회 하여 주십시요!")
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_ed_agnt_nm.value) || FSCommUtil.gfn_trim(this.lv_ed_agnt_nm.value).length < 2) {            
        FSCommUtil.gfn_validate(this, '대리인 성명을 입력해 주십시오.')        
        this.lv_ed_agnt_nm.error = true
        this.$refs['ed_agnt_nm'].focus()
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_ed_agnt_rname_no.value1) || FSCommUtil.gfn_trim(this.lv_ed_agnt_rname_no.value1).length != 6) {            
        FSCommUtil.gfn_validate(this, '실명번호 앞자리를 입력해 주십시오.')        
        this.lv_ed_agnt_rname_no.error1 = true
        this.$refs['ed_agnt_rname_no1'].focus() // 추가
        return false
      }

      if(!this.lv_isMobile) {        
        if(FSCommUtil.gfn_isNull(this.lv_ed_agnt_rname_no.value2) || FSCommUtil.gfn_trim(this.lv_ed_agnt_rname_no.value2).length != 7) {            
          FSCommUtil.gfn_validate(this, '실명번호 뒷자리를 입력해 주십시오.')        
          this.lv_ed_agnt_rname_no.error2 = true
          this.$refs['ed_agnt_rname_no2'].focus() // 추가
          return false
        }
      } else {
        if(this.lv_masked_val.length != 7) {
          FSCommUtil.gfn_validate(this, '실명번호 뒷자리를 입력해 주십시오.')
          this.lv_ed_agnt_rname_no.error2 = true
          this.$refs['ed_agnt_rname_no2'].focus() // 추가
          return false
        }
      }

      

      // if(!FSCommUtil.gfn_isNull(this.lv_ed_agnt_rname_no.value) && FSCommUtil.gfn_trim(this.lv_ed_agnt_rname_no.value).length != 13) {            
      //   FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')        
      //   this.lv_ed_agnt_rname_no.error = true
      //   this.$refs['ed_agnt_rname_no'].focus() // 추가
      //   return false
      // }

      if(FSCommUtil.gfn_trim(this.lv_ed_cust_nm.value) === FSCommUtil.gfn_trim(this.lv_ed_agnt_nm.value)) {            
        FSCommUtil.gfn_validate(this, '본인,대리인 성명이 동일합니다.계속 진행하시겠습니까?')

        // 확인 창 추가
        this.lv_ed_agnt_nm.error = true
        this.$refs['ed_agnt_nm'].focus()
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_cob_naty_cntry_cd.value)) {            
        FSCommUtil.gfn_validate(this, '국적을 입력해 주십시오.')        
        this.lv_cob_naty_cntry_cd.error = true        
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_cob_agnt_rname_gb.value)) {            
        FSCommUtil.gfn_validate(this, '대리인 실명번호종류를 입력해 주십시오.')
        this.lv_cob_agnt_rname_gb.error = true        
        return false
      }

      // 본인정보 , 대리인 정보 실명번호 비교
      if(FSCommUtil.gfn_trim(this.lv_cust_info.rlnm_cnf_no_encr).substr(0,7) === FSCommUtil.gfn_trim(this.lv_ed_agnt_rname_no.value2)) {
        FSCommUtil.gfn_validate(this, '본인,대리인의 실명번호가 동일합니다.')
        this.lv_ed_agnt_rname_no.error2 = true
        this.$refs['ed_agnt_rname_no2'].focus() // 추가
        return false
      }

      if(this.lv_cob_naty_cntry_cd.value !== 'KR') {
        if(FSCommUtil.gfn_isNull(this.lv_ed_agnt_enm.value)) {            
          FSCommUtil.gfn_validate(this, '외국인일경우 영문명을 입력하세요.')        
          this.lv_ed_agnt_enm.error = true
          this.$refs['ed_agnt_enm'].focus() // 추가
          return false
        }

        if(FSCommUtil.gfn_isNull(this.lv_ed_agnt_psprt_no.value)) {            
          FSCommUtil.gfn_validate(this, '외국인일경우 여권번호를 입력하세요.')        
          this.lv_ed_agnt_psprt_no.error = true
          this.$refs['ed_agnt_psprt_no'].focus() // 추가
          return false
        }

        if(FSCommUtil.gfn_isNull(this.lv_ed_agnt_psprt_no.value) || FSCommUtil.gfn_trim(this.lv_ed_agnt_psprt_no.value).length <8) {            
          FSCommUtil.gfn_validate(this, '여권번호가 잘못 입력되었습니다.')        
          this.lv_ed_agnt_psprt_no.error = true
          this.$refs['ed_agnt_psprt_no'].focus() // 추가
          return false
        }

        if(FSCommUtil.gfn_isNull(this.lv_cal_birth_dt.value)) {            
          FSCommUtil.gfn_validate(this, '외국인일경우 생년월일을 입력하세요.')        
          this.lv_cal_birth_dt.error = true          
          return false
        }

        if(FSCommUtil.gfn_isNull(this.lv_rdo_agnt_sex.value)) {            
          FSCommUtil.gfn_validate(this, '외국인일경우 성별을 입력하세요.')        
          this.lv_rdo_agnt_sex.error = true
          return false
        }
      }

      if(FSCommUtil.gfn_isNull(this.lv_cob_main_dm.value)) {            
        FSCommUtil.gfn_validate(this, '주소구분을 입력해 주십시오.')        
        this.lv_cob_main_dm.error = true        
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_home_addr.zipNum) || FSCommUtil.gfn_isNull(this.lv_home_addr.value1)) {            
        FSCommUtil.gfn_validate(this, '주소를 입력해 주십시오.')        
        this.lv_home_addr.error = true
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_home_no.value1) || FSCommUtil.gfn_trim(this.lv_home_no.value1).length <= 1) {
        FSCommUtil.gfn_validate(this, '전화번호를 입력해 주십시오.')        
        this.lv_home_no.error = true
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_home_no.value2) || FSCommUtil.gfn_trim(this.lv_home_no.value2).length <= 2) {
        FSCommUtil.gfn_validate(this, '전화번호를 입력해 주십시오.')        
        this.lv_home_no.error = true
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_home_no.value3) || FSCommUtil.gfn_trim(this.lv_home_no.value3).length <= 3) {
        FSCommUtil.gfn_validate(this, '전화번호를 입력해 주십시오.')        
        this.lv_home_no.error = true
        return false
      }

      if(this.lv_phone_no.value1 !== '' || !FSCommUtil.gfn_isNull(this.lv_phone_no.value2) || !FSCommUtil.gfn_isNull(this.lv_phone_no.value3))
      {
        if(FSCommUtil.gfn_isNull(this.lv_phone_no.value1)) {
          FSCommUtil.gfn_validate(this, '휴대폰번호를 입력해 주십시오.')        
          this.lv_phone_no.error = true
          return false
        }

        if(FSCommUtil.gfn_isNull(this.lv_phone_no.value2) || FSCommUtil.gfn_trim(this.lv_phone_no.value2).length <= 2) {
          FSCommUtil.gfn_validate(this, '휴대폰번호를 입력해 주십시오.')        
          this.lv_phone_no.error = true
          return false
        }

        if(FSCommUtil.gfn_isNull(this.lv_phone_no.value3) || FSCommUtil.gfn_trim(this.lv_phone_no.value3).length <= 2) {
          FSCommUtil.gfn_validate(this, '휴대폰번호를 입력해 주십시오.')        
          this.lv_phone_no.error = true
          return false
        }
      }
      
      if(!FSCommUtil.gfn_isNull(this.lv_email_addr.value1) || !FSCommUtil.gfn_isNull(this.lv_email_addr.value2)) {
        if(FSCommUtil.gfn_isNull(this.lv_email_addr.value1) || FSCommUtil.gfn_trim(this.lv_email_addr.value1).length <= 2) {
          FSCommUtil.gfn_validate(this, '이메일 주소를 입력해 주십시오.')        
          this.lv_email_addr.error = true
          return false
        }

        if(FSCommUtil.gfn_isNull(this.lv_email_addr.value2) || FSCommUtil.gfn_trim(this.lv_email_addr.value2).length <= 2) {
          FSCommUtil.gfn_validate(this, '이메일 주소를 입력해 주십시오.')        
          this.lv_email_addr.error = true
          return false
        }
      }      

      if(FSCommUtil.gfn_isNull(this.lv_agnt_rltn.value)) {
        FSCommUtil.gfn_validate(this, '본인과의관계를 선택해 주십시오.')        
        this.lv_agnt_rltn.error = true
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_cal_asgn_dt.value)) {
        FSCommUtil.gfn_validate(this, '지정일을 입력하세요.')        
        this.lv_cal_asgn_dt.error = true
        return false
      }

      if(FSCommUtil.gfn_isNull(this.lv_cal_exp_dt.value)) {
        FSCommUtil.gfn_validate(this, '만기일을 입력하세요.')        
        this.lv_cal_exp_dt.error = true
        return false
      }

      //let busyDate = this.getStore('fsStore').getters.getBasInfo.data.busyDate
      
      
      // let vExpDt = DateUtil.fn_AddMonth(moment(busyDate).subtract(1, "days").format("YYYY-MM-DD"), 12)
      // debugger
      // if (vExpDt < this.lv_cal_exp_dt.value) {
      //   FSCommUtil.gfn_validate(this, '만기일은 지정일로부터 1년까지만 가능합니다.\n만기일을 수정합니다.')        
      //   this.lv_cal_exp_dt.value = vExpDt
      // }

      if(this.lv_agnt_trst_cntnt.value.length === 0) {
        FSCommUtil.gfn_validate(this, '위임내용을 선택하세요.')
        return false
      }

      let busyDate = this.getStore('fsStore').getters.getBasInfo.data.busyDate
      //const busyDate = this.lv_basInfo.busyDate

      if(!FSCommUtil.gfn_isNull(this.lv_cal_asgn_dt.value) && !FSCommUtil.gfn_isNull(this.lv_cal_exp_dt.value)) {
        let tmpAsgnDt = this.lv_cal_asgn_dt.value.replace(/-/g, '')
        let tmpExpDt = this.lv_cal_exp_dt.value.replace(/-/g, '')
        let tmpAsgnDtAddYear = DateUtil.fn_AddMonth(moment(busyDate).subtract(1, "days").format("YYYYMMDD"), 12)       
        if(tmpAsgnDtAddYear < tmpExpDt){
          FSCommUtil.gfn_validate(this, '만기일은 지정일로부터 1년까지만 가능합니다.\n만기일을 수정합니다.')
          this.lv_cal_exp_dt.value = moment(tmpAsgnDtAddYear).format('YYYY-MM-DD')
          this.lv_cal_exp_dt.error = true
          return false
        }
      }

      if (this.ds_list.length > 0) {
        this.ds_list.forEach(item => {          
          if (item.agnt_sno != this.ds_agent.bfcer_cust_no){
            if(String(item.asgn_ymd) + String(item.exp_ymd) == String(this.lv_cal_asgn_dt.value) + String(this.lv_cal_exp_dt.value)){
              FSCommUtil.gfn_validate(this, '동일기간 정보가 존재합니다.')
              this.lv_cal_asgn_dt.error = true
              return false
            }
          }
        })
      }

      return true
    },
    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 고객정보조회 / 1: 우편번호
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      console.log(pData)
      switch (type) {
        case 0:
          // 고객번호 매핑 후 고객정보조회
          this.lv_ed_cust_no.value = pData;
          this.fn_SearchValid({ key: "v" });
          break;
        case 1:
          // 자택(HOME), 직장(JOB)
          //if( pData.btnFlag == 'HOME' ) {
          this.lv_home_addr.zipNum = pData.vZipcd_head + pData.vZipcd_tail; // 우편번호
          this.lv_home_addr.value1 = pData.vZipcd_ubmyundong; // 우편번호주소
          this.lv_home_addr.value2 = pData.v_bldg_no; // 상세주소암호화
          this.lv_home_addr.value3 = pData.v_stand_addr; // 건물번호
          this.lv_home_addr.value4 = pData.v_adtn_addr; // 부가주소
          //this.lv_home_addr.standard  = pData.v_addr_stdzt_yn == 'Y' ? true : false // 표준화여부
          console.log(this.lv_home_addr);
          //}
          break;
      }
    },
    
    /******************************************************************************
     * Function명 : fn_NextPage
     * 설명       : 수정/삭제/등록 후 다음 페이지 팝업
     ******************************************************************************/    
    async fn_NextPage(type) {      
      if (FSCommUtil.gfn_isNull(type)){        
        return
      } else {
        if(type !== 'U' && type !== 'I' && type !== 'D' && !this.lv_isInsert){
          return
        }
      }      

      let lv_Vm = this
      let tmpContentTitle = ''
      let tmpContent = []      
      let isProcess = lv_Vm.getStore('fsStore').getters.getState.isProcess            
      let t_menu_id = this.$route.params.menu_id // 이전 화면
      let msg = ''
      
      if(this.lv_btn_status !== 'D' && isProcess) {        
        await lv_Vm.fn_ReportPrint()
      }

      switch(t_menu_id){
        case 'MSPFS300M' : 
          msg = '그룹신규가입'
          break
        case 'MSPFS303M' : 
          msg = '연금계좌신규가입'
          break
        case 'MSPFS307M' : 
          msg = '계좌신규가입'
          break
        // case 'MSPFS108M' : 
        //   msg = '고객정보정정'
        //   break
        // case 'MSPFS310M' : 
        //   msg = '환매신청'
        //   break
        // case 'MSPFS314M' : 
        //   msg = '은행송금'
        //   break
        // case 'MSPFS317D' : 
        //   msg = '실시간입금처리'
        //   break
      }
      switch (type) {
        case 'I':
          tmpContentTitle = '등록완료'
          tmpContent = ['등록이 완료되었습니다.']
          break;
        case 'U':
          tmpContentTitle = '변경완료'
          tmpContent = ['수정이 완료되었습니다.']            
          break;
        case 'D':
          tmpContentTitle = '해지완료'
          tmpContent = ['삭제가 완료되었습니다.']            
          break;
      }
      
      let t_type = 0      
      if(isProcess) {
        t_type = 8
        tmpContent.push(msg + '을 계속 진행하겠습니까?')
      }else {
        if(this.lv_btn_status !== 'D'){ // 단일 화면 시, 입력, 수정 시에만 전자서식 호출
          t_type = 9
        } else {
          t_type = 8
        }
      }

      let t_popupObj = {
        btnType: 1,
        cancel: this.lv_btn_status !== 'D' ? true : false,
        confirm: true,
        confirmFunc: isProcess ? lv_Vm.fn_GoPage : lv_Vm.fn_ReportPrint,
        confirmData: {
          bfcer_cust_no: lv_Vm.lv_ed_cust_no.value
        },
        content: tmpContent,
        contentTitle: tmpContentTitle,
      }

      lv_Vm.fn_AlertPopup(t_type, t_popupObj)      
    },
    /******************************************************************************
     * Function명 : fn_GoPage
     * 설명       : 수정/삭제/등록 후 다음 페이지 이동     
     ******************************************************************************/    
    async fn_GoPage() {            
      let paramObj = {}
      let t_menu_id = ''
      if( !FSCommUtil.gfn_isNull(this.$route.params.menu_id) ) {
        t_menu_id = this.$route.params.menu_id
      }

      paramObj.rmvl_srn_id      = t_menu_id
      paramObj.ed_cust_no       = this.$route.params.bfcer_cust_no
      paramObj.ed_group_no      = this.$route.params.ed_group_no
      paramObj.rdo_agnt_chk_yn  = "Y" // 위임내용 체크 없이 수정

      this.$router.push({ name: t_menu_id, params: paramObj })
    },

    /******************************************************************************
      * Function명 : fn_ReportPrint
      * 설명       : 전자서식 호출
      ******************************************************************************/
    async fn_ReportPrint () {
      console.log('fn_ReportPrint....', this.ds_transaction)      
      

      let today = FSCommUtil.gfn_strToday()
      if (FSCommUtil.gfn_isNull(this.ds_transaction) || this.ds_transaction.length === 0){
        return 
      }      

      let FS000007 = {formId: 'FS000007'} // as-is : RCO000009 , FS000007_위임장(대리인확인서)
      let FS000026 = {formId: 'FS000026'} // as-is : RCO000112 , FS000026_위임장(대리인확인서)-이체출금
      let FS000021 = {formId: 'FS000021'} // as-is : RCO000094 , FS000021_개인신용정보의 제공,조회 및 이용동의서(업무대리인)
      let FS000006 = {formId: 'FS000006'} // as-is : RCO000007 , FS000006_신용정보의 제공·조회 및 이용에 관한 고객권리안내문 (삭제)

      let formList = []
      let searchData = {}
      let obj = {}      
      this.ds_transaction.forEach(item => {
        obj = {}
        searchData = item
        if(FSCommUtil.gfn_isNull(item.rprt_frm_no)){
          return false
        }        
        switch(item.rprt_frm_no) {
          case '0009':
            obj = FS000007
            break
          case '0112':
            obj = FS000026
            break
          case '0094':
            obj = FS000021
            break          
        }
        obj.params = {
          reportParam: JSON.stringify(searchData),
          reportUrl: 'reportCO070500_Info',
        }
        formList.push(obj)
      })

      // FS000006.params = {}
      // formList.push(FS000006)
      
      // let t_menu_id = this.$route.params.menu_id
      // let t_scrn_id = this.$options.screenId
      // if(t_menu_id) {
      //   t_scrn_id += '_' + t_menu_id
      // }

      // isPrint : false (*대리인등록은 단독 화면 없음)

      let isProcess = this.getStore('fsStore').getters.getState.isProcess
      if(this.lv_btn_status !== 'D' && isProcess){
        FSInfoUtil.commReportInfo(this, formList)        
      } else {
        FSInfoUtil.commReportInfo(this, formList, this.fn_GoPage)        
      }
    },

    /******************************************************************************
    * Function명 : fn_NumKeyUp
    * 설명       : 실명번호 앞자리 input 이벤트 핸들러
    ******************************************************************************/
    fn_NumKeyUp () {
      if (this.lv_ed_agnt_rname_no.value1 && this.lv_ed_agnt_rname_no.value1.length === 6 && this.lv_ed_agnt_rname_no.value2.length === 0) {
        this.$refs['ed_agnt_rname_no2'].focus()  // 실명번호 뒷자리
      }
    },

    _fn_End(){return}//코딩 종료 함수
  },
};
</script>
<style scoped>
</style>
